import Vue from "vue";
async function get_jg_list(type) {
  let data_tree = [];
  let goodsType = [];
  let doctors = [];
  let personnelIdentity = [];
  let params = { type: type };
  await Vue.axios.post("TRANSACTION_LIST", params).then((result) => {
    // console.log("获取交易", result.data);
    // 重新组装结构

    data_tree = result.data.team;
    goodsType = result.data.goodsType;
    doctors = result.data.doctors;
    personnelIdentity = result.data.personnelIdentity;
    // console.log(result.data.goodsType)
    // console.log("++++++++++++",data_tree,goodsType)
  });
  return {
    data_tree,
    goodsType,
    doctors,
    personnelIdentity
  };
}
function getRandom(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
// 返回我们要的颜色
function getRandomColor() {
  var c1 = getRandom(0, 255);
  var c2 = getRandom(0, 255);
  var c3 = getRandom(0, 255);
  return "rgb(" + c1 + "," + c2 + "," + c3 + ")";
}
export default { get_jg_list, getRandomColor };
