<template>
  <div class="select-card">
    <div class="select-card-top">
      <div>对比分析</div>
    </div>
    <div class="select-card-bottom">
      <div class="select-card-bottom-right">
         <span style="color:#3BAF61;font-size:16px;">月</span>
        <!-- <el-select v-model="timeType" placeholder="请选择" size="small">
          <el-option
            v-for="item in dateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
        <div v-show="timeType == 3">
          <el-date-picker
            class="select-day"
            style="margin-left: 20px; margin-right: 20px"
            format="yyyy-MM"
            v-model="timeMonth"
            type="monthrange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            :picker-options="pickerOptions3"
            size="small"
            @change="checkTimeType"
          >
          </el-date-picker>
        </div>
        <el-button
          type="primary"
          size="small"
          @click="busEmit()"
          :loading="isLoading"
          >查询</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
let id = 0;
import Bus from "@/utils/bus";
export default {
  data() {
    return {
      dateOptions: [
        {
          value: 3,
          label: "月",
        },
      ],
      // 月
      pickerOptions3: {
        disabledDate(date) {
           let t = new Date().getDate();
          return date.getTime() > Date.now() - 8.64e7 * t;
        },
        shortcuts: [
          {
            text: "今年至今",
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近六个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      select_data_tree: "", // 选中的数据维度权限
      data_tree: [], // 数据维度树
      productClass: [], // 产品类目列表
      selectClass: [], // 选中的类目
      timeMonth: "", // 选中月
      startTime: "", // 开始时间
      endTime: "", // 结束时间
      week1: "", // 本月第几周
      week2: "", // 本月第几周
      isLoading: false,
      timeType:3,
      changeType:false
    };
  },
  created() {
    this.defaultDate()
    this.busEmit()
  },
  methods: {
    checkTimeType() {
      this.changeType=true
      // 广播出去
      // Bus.$emit("overviewTimeType", this.timeType);
    },
    defaultDate () {
      //获取新的时间
      let date = new Date()
      //获取当前时间的年份转为字符串
      let year = date.getFullYear().toString()
      //获取月份，由于月份从0开始，此处要加1，判断是否小于10，如果是在字符串前面拼接'0'
      let month = date.getMonth()  < 10 ? '0' + (date.getMonth() ).toString() : (date.getMonth() ).toString()
      //获取天，判断是否小于10，如果是在字符串前面拼接'0'
      let da = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString()
      //字符串拼接，开始时间，结束时间
      let end = year + '-' + month  //当天
      let beg = year + '-' + month    //当月第一天
      this.timeMonth = [beg, end] //将值设置给插件绑定的数据
    },
    busEmit() {
      if (this.timeType == 3) {
        if (this.timeMonth&&this.changeType) {
          console.log(this.timeMonth);
          // 年
          let year0 = this.timeMonth[0].getFullYear();
          // 月
          let month0 = this.timeMonth[0].getMonth() + 1;
          // 年
          let year1 = this.timeMonth[1].getFullYear();
          // 月
          let month1 = this.timeMonth[1].getMonth() + 1;
          this.startTime = `${year0}${month0 < 10 ? "0" + month0 : month0}`;
          this.endTime = `${year1}${month1 < 10 ? "0" + month1 : month1}`;
        } else {
          this.startTime =parseInt(this.timeMonth[0].replace(/\-/g,""))
          this.endTime = parseInt(this.timeMonth[1].replace(/\-/g,""))
        }
      }
      let params = {
        dateType: this.timeType,
        // deptIds: this.select_data_tree.length > 0 ? JSON.stringify(this.select_data_tree) : '',
        // productLineIds: this.selectClass.length > 0 ? JSON.stringify(this.selectClass) : '',
        startTime: this.startTime,
        endTime: this.endTime,
      };
      console.log(params)
      Bus.$emit("compareTimeType", params);
      this.$emit('transfer',params)
    },
    formatDate(number) {
      var date = new Date(number);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return YY + MM + DD;
    },
  },
};
</script>

<style>
@media (max-width: 1440px) {
  /* 小于1440 */
  .week-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .week-input2 {
    margin-right: 0 !important;
    margin-top: 10px;
  }
  .text-btn2 {
    margin-left: 8px !important;
  }
  .text-btn3 {
    margin-left: 8px !important;
    margin-right: 0px !important;
  }
  .select-day {
    margin-left: 12px !important;
  }
  .class-list {
    margin-left: 8px !important;
  }
}
</style>

<style scoped lang="scss">
.select-card {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  margin-top: 24px;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 13px 24px 13px 24px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    .question {
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }
    .question:hover {
      cursor: pointer;
    }
  }
  .select-card-bottom {
    padding: 8px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .select-card-bottom-left {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .class-list {
        margin-left: 24px;
        width: 200px;
      }
    }
    .select-card-bottom-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>