<template>
  <div class="box">
    <div class="card-box" id="scroll" ref="wrapper">
      <div
        v-for="(item, index) in cardList_profit.slice(0, 1)"
        :key="index"
        class="card-box-item card-box-item1"
        :style="{
          backgroundImage: 'url(' + item.lineColor + ')',
          backgroundSize: '100% 100%',
        }"
      >
        <div class="left flex-item">
          <div class="card-box-item-title-box">
            <div class="card-box-item-title">
              {{ item.title }}
            </div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.remarks"
              placement="bottom"
            >
              <img src="@/assets/qs.png" />
            </el-tooltip>
          </div>
          <div class="card-box-item-num-box">
            <div class="card-box-item-num">
              {{ item.num? String(item.num).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )
                : "--" }}
            </div>
          </div>
          <div class="card-box-item-bottom" v-if="item.with||item.ring">
            <div class="card-box-item-bottom-item">
              <div class="card-box-item-bottom-item-title">同比</div>
              <div v-if="item.with>0" class="rise">
                <img class="arrow" src="@/assets/rise.png" />
                <div class="card-box-item-bottom-item-num1">
                  {{ item.with }}%
                </div>
              </div>
              <div v-else class="drop">
                <img class="arrow" src="@/assets/drop.png" />
                <div class="card-box-item-bottom-item-num2">
                  {{ item.with }}%
                </div>
              </div>
            </div>
            <div class="card-box-item-bottom-item card-box-item-bottom-item2">
              <div class="card-box-item-bottom-item-title">环比</div>
              <div v-if="item.ring>0" class="rise">
                <img class="arrow" src="@/assets/rise.png" />
                <div class="card-box-item-bottom-item-num1">
                  {{ item.ring }}%
                </div>
              </div>
              <div v-else class="drop">
                <img class="arrow" src="@/assets/drop.png" />
                <div class="card-box-item-bottom-item-num2">
                  {{ item.ring }}%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center" style="display: flex; width: 70px">
          <div class="imgCon" style="flex: 1">
             <img :src="cardList_profit[0].toUrl" alt="" />
          </div>
        </div>
        <div class="right-1 flex-item">
          <div class="item_center">
            <p class='title'>实际销售额（万）</p>
            <p class='count'>{{cardList_profit[0].actualSaleAmount}}</p>
          </div>
        </div>
        <div class="right-2 " style="width: 12px">
          <div class='block'>—</div>
        </div>
        <div class="right-3 flex-item">
          <div class="item_center">
            <p class='title'>实际费用额（万）</p>
            <p class='count'>{{cardList_profit[0].actualCostAmount}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card-box card2" id="scroll" ref="wrapper">
      <div
        v-for="(item, index) in cardList_profit.slice(1, 4)"
        :key="index"
        class="card-box-item"
        @click="checkCard(index)"
        :style="{
          backgroundImage: 'url(' + item.lineColor + ')',
          backgroundSize: '100% 100%',
        }"
      >
        <div>
          <div class="card-box-item-title-box">
            <div class="card-box-item-title">
              {{ item.title }}
            </div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.remarks"
              placement="bottom"
            >
              <img src="@/assets/qs.png" />
            </el-tooltip>
          </div>
          <div class="card-box-item-num-box">
            <div class="card-box-item-num" v-if="item.title.indexOf('率')==-1">
              {{ item.num?String(item.num).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )
                : "--" }}
            </div>
            <div class="card-box-item-num" v-else>
              {{ item.num?String(item.num).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )
                +"%": "--" }}
            </div>
          </div>
          <div class="card-box-item-bottom" v-if="item.with||item.ring">
            <div class="card-box-item-bottom-item">
              <div class="card-box-item-bottom-item-title">同比</div>
              <div v-if="item.with>0" class="rise">
                <img class="arrow" src="@/assets/rise.png" />
                <div class="card-box-item-bottom-item-num1">
                  {{ item.with }}%
                </div>
              </div>
              <div v-else class="drop">
                <img class="arrow" src="@/assets/drop.png" />
                <div class="card-box-item-bottom-item-num2">
                  {{ item.with }}%
                </div>
              </div>
            </div>
            <div class="card-box-item-bottom-item card-box-item-bottom-item2">
              <div class="card-box-item-bottom-item-title">环比</div>
              <div v-if="item.ring > 0" class="rise">
                <img class="arrow" src="@/assets/rise.png" />
                <div class="card-box-item-bottom-item-num1">
                  {{ item.ring>0 }}%
                </div>
              </div>
              <div v-else class="drop">
                <img class="arrow" src="@/assets/drop.png" />
                <div class="card-box-item-bottom-item-num2">
                  {{ item.ring }}%
                </div>
              </div>
            </div>
          </div>
          <div class="card-box-item-bottom" v-if="item.saleGap||item.profit_gap">
            <div class="card-box-item-bottom-item" v-if='item.profit_gap'>
              {{item.gapName}}<span class="saleGap">{{ item.profit_gap }}万</span>
            </div>
            <div class="card-box-item-bottom-item" v-if='item.saleGap'>
              {{item.gapName}}<span class="rate">{{ item.saleGap }}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/utils/bus";
import { mapGetters } from "vuex";
export default {
    props:['cardList'],
  data() {
    return {
      scrollToX: 0,
      isShowRight: true,
      isShowLeft: false,
      timeType: 1, // 时间类型
    };
  },
  computed: {
    ...mapGetters(["cardList_profit"]),
  },
  created() {
    console.log(this.cardList_profit)
  },
  mounted() {
    
  },
  methods: {},
};
</script>


<style scoped lang='scss'>
.box {
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  margin-top: 24px;
}
.svg-icon {
  width: 10px;
  height: 10px;
}
.card-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
  /* justify-content: space-between; */
}
.card-box:last-child {
 margin-bottom: 0;
  /* justify-content: space-between; */
}

.card-box:hover {
  cursor: pointer;
}
.card-box::-webkit-scrollbar {
  display: none;
}
.card-box-item {
  // width: calc(33% - 10px);
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  padding: 24px;
  box-sizing: border-box;
  margin-right: 12px;
  white-space: nowrap;
  /* flex-basis: 370px;  */
  height: 156px;
  flex-shrink: 0;
  display: flex;
   flex: 1;
}
.card-box-item:last-child{
  margin-right: 0;
}

.card2{
  .card-box-item:last-child{
    margin-right: 0;
  }
}
.card-box-item1 {
  flex: 1;
}
.card-box-item-icon {
  width: 28px;
  height: 28px;
}
.card-box-item-icon img {
  width: 100%;
  height: 100%;
}
.card-box-item-title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card-box-item-title {
  font-size: 14px;
  color: #262626;
  margin-top: 4px;
}
.card-box-item-title0 {
  font-size: 14px;
  color: #ffffff;
  margin-top: 4px;
}
.card-box-item-title-box img {
  width: 16px;
  height: 16px;
  margin-left: 3px;
  margin-bottom: -4px;
}
.card-box-item-num-box {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 14px;
}
.card-box-item-num {
  font-weight: bold;
  color: #262626;
  font-size: 32px;
  font-family: MiSans-Demibold;
}
.card-box-item-num0 {
  font-weight: bold;
  color: #ffffff;
  font-size: 32px;
  font-family: MiSans-Demibold;
}
.card-box-item-unit {
  color: #00000085;
  font-size: 16;
  margin-left: 5px;
  margin-bottom: 5px;
}
.card-box-item-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card-box-item-bottom-item {
  display: flex;
  flex-direction: row;
  height: 22px;
  font-size: 14px;
}
.card-box-item-bottom-item2 {
  margin-left: 16px;
}
.rise {
  min-width: 47px;
  height: 100%;
  background: #ffffff;
  border-radius: 4px;
  opacity: 0.9;
  display: flex;
  line-height: 18px;
  margin-left: 2px;
  color: #ff5322;
  padding: 2px 4px;
}
.drop {
  min-width: 47px;
  height: 100%;
  background: #ffffff;
  border-radius: 4px;
  opacity: 0.9;
  display: flex;
  line-height: 18px;
  margin-left: 2px;
  color: #00a4ff;
  padding: 2px 4px;
}

.arrow {
  display: inline;
  width: 10px;
  height: 10px;
  margin-left: 2px;
  margin-top: 4px;
  margin-right: 4px;
}
.card-box-item-bottom-item-title {
  font-size: 12px;
  color: #595959;
  margin-right: 4px;
  line-height: 22px;
}
.card-box-item-bottom-item-title0 {
  font-size: 12px;
  color: #ffffff;
  margin-right: 4px;
}
.card-box-item-bottom-item-num1 {
  font-size: 12px;
  color: #ff5322;
}
.card-box-item-bottom-item-num2 {
  font-size: 12px;
  color: #00a1ff;
}
.select-box {
  position: absolute;
  top: 0;
  right: 0;
}
.select-box img {
  width: 18px;
  height: 18px;
}
.saleGap {
  width: 18px;
  height: 18px;
  font-size: 14px;
  font-family: MiSans-Regular;
  font-weight: 400;
  color: #ff7c00;
  // line-height: 18px;
  margin: 0 4px;
  // line-height: 18px;
}
.rate {
  font-family: MiSans-Regular;
  font-weight: 400;
  color: #0e7744;
  line-height: 18px;
  margin: 0 4px;
  line-height: 18px;
}
.flex-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #595959;
    line-height: 12px;
  }
  .count{
      font-size: 22px;
font-family: MiSans-Medium;
font-weight: 500;
color: #262626;
line-height: 22px;
margin-top: 4px;

  }
}
.item_center {
  margin: auto;
}
.right-2{
    display: flex;
    .block{
        margin: auto;
        font-weight: 600;
    }
}
.imgCon {
    width: 60px;
    height: 100%;
    display: flex;
    img {
      width: 60px;
      height: 22px;
      display: inline-block;
      margin: auto;
    }
  }
  @media screen and (min-width:2000px) {
    .card-box-item {
        border-radius: 15px;
    }
}
</style>