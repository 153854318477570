<template>
  <div class="select-card-child">
    <div
      class="select-card-bottom"
      v-watermark="{ text: text, textColor: 'rgba(180, 180, 180, 0.3)' }"
    >
      <div
        :id="'myChart2' + id"
        :style="{ width: '100%', height: '450px' }"
      ></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import colors from "@/utils/color";
export default {
  data() {
    return {
      xAxisData: [],
      yAxisData: [],
      legendData: [],
      seriesData: [],
      lineColor: [],
      itemStyle: {
        color: "",
      },
      text: "",
    };
  },
  props: ["id", "tissueComparison"],
  created() {
    if (sessionStorage.getItem("userInfo")) {
      this.text = JSON.parse(sessionStorage.getItem("userInfo")).name;
    }
    console.log("对比分析-趋势图", this.tissueComparison);
    this.lineColor = colors;
    this.legendData = this.tissueComparison.chartLabels;
    this.xAxisData = this.tissueComparison.dateList;
    this.tissueComparison.chartLabels.forEach((element, index) => {
      let temp = {};
      temp.name = element;
      temp.data = this.tissueComparison.trendDataList[index];
      temp.smooth = true;
      temp.type = "line";
      this.seriesData.unshift(temp);
    });
    console.log(this.legendData);
    console.log(this.seriesData);
    console.log(this.xAxisData);
  },
  mounted() {
    this.$nextTick(() => {
      this.drawLine();
    });
    // this.$watermark.set("水印内容",'myChart')
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById("myChart2" + this.id));
      // 绘制图表
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          extraCssText: "box-shadow: 0px 2px 12px 0px rgba(64, 98, 117, 0.1);",
          textStyle: {
            color: "#262626", //设置文字颜色
            fontSize: 12,
          },
          padding: 12,
          backgroundColor: "#FFFFFF", //设置背景颜色
          borderColor: "#FFFFFF", //设置边框颜色
          formatter(params) {
            let html = "";
            for (let i in params) {
              html += `<span style="display:inline-block;width:10px;height:10px;background:${params[i].color};
border-radius: 2px;margin-right:4px;"></span><span>${params[i].seriesName}:</span><span style="min-width:20px;text-align:right;display:inline-block;float:right">${params[i].value}<span style="margin-left:4px;">万</span></span><br/>`;
            }
            // 返回值就是html代码可以使用标签
            return html;
          },
        },
        color: this.lineColor,
        legend: {
          left: "left",
          itemWidth: 12,
          itemHeight: 12,
          type: "scroll",
          orient: "horizontal",
          itemGap: 24,
          icon: "roundRect",
          data: this.legendData,
        },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: this.xAxisData,
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#113d5e",
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#8C8C8C",
            },
          },

          axisTick: {
            show: false, //y轴坐标点消失
          },
          axisLine: {
            show: false, //y轴线消失
          },
        },
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false, //y轴坐标点消失
            },
            axisLine: {
              show: false, //y轴线消失
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#F0F0F0",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#8C8C8C",
              },
            },
          },
          {
            type: "value",
            axisTick: {
              show: false, //y轴坐标点消失
            },
            axisLine: {
              show: false, //y轴线消失
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#F0F0F0",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#8C8C8C",
              },
            },
          },
        ],
        series: this.seriesData,
      };
      option && myChart.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss">
.select-card-child {
  background: #ffffff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: none;
  border-top-left-radius: none;
  width: 100%;
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 13px 24px 13px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .select-card-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .main {
    width: 100%;
    min-height: 500px;
  }
}
</style>
