<template>
  <div class="context">
    <!-- 交易概览 -->
    <overview :showCheck="false"></overview>
    <!-- 横向滚动卡片组 -->
    <CardGrid4 />
    <ChartLine />
    <Compare @transfer="getUser" />
    <Compare1 :date="transfer" :key="random+'h'" v-if="transfer.dateType"></Compare1>
  </div>
</template>

<script>
import Overview from "./components/overview.vue";
import CardGrid4 from "./components/cardgrid4.vue";
import ChartLine from "./components/chart_line";
import Compare from "./components/compare";
import Compare1 from "./components/compare1";
export default {
  components: {
    Overview,
    CardGrid4,
    ChartLine,
    Compare,
    Compare1,
  },
  data() {
    return {
      chartTypes: [1, 2, 3, 4],
      transfer:{},
      random:0

    };
  },
  methods:{
    getUser(msg){
       this.transfer= msg
       console.log(this.transfer)
       this.random = Math.random()
     }
  }
};
</script>